import React from 'react';
import '../static/Pastor.css';
import pastorImage from "../images/Pastor.jpg";

const Pastor = () => {
    return (
        <section className='darkSection' class="padding" id="pastor">
                <img id = "pastor-image"src={pastorImage} alt="pastor image"/>
                <h3 className='siteHeadings'>Pastor Andy Tso</h3>
                <div className='aboutSummary'>
                    <h3>Motto</h3>
                    <p id = "quote">
                        "Discipling Godly Men and Women,
                        Building Godly Families,
                        Raising Godly Children"
                    </p>
                </div>
                <br></br>
                <div className='aboutSummary'>
                    <h3>Some Fun Facts!</h3>
                    <ul>
                        <li>He grew up in a restaurant family in NYC.</li>
                        <li>He is fluent in Mandarin, Cantonese, Taiwanese and “Brooklynese”.</li>
                        <li>His claim to fame: His father invented Orange Beef.</li>
                    </ul>
                </div>
        </section>
    );
}

export default Pastor;
